import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || "en";
  const isRTL = lang === "ar";

  return (
    <div className="container mx-auto px-4 py-8" dir={isRTL ? "rtl" : "ltr"}>
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        {t("privacy.title")}
      </h1>
      {/* <p className="text-sm text-gray-600 mb-6">
        {t("privacy.lastUpdate")}: 2025
      </p> */}
      <div className="space-y-6 text-gray-700">
        <p>{t("privacy.intro")}</p>

        <section>
          <h2 className="text-lg font-semibold mb-2">
            {t("privacy.section1.title")}
          </h2>
          <p>{t("privacy.section1.content")}</p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mb-2">
            {t("privacy.section2.title")}
          </h2>
          <p>{t("privacy.section2.content")}</p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mb-2">
            {t("privacy.section3.title")}
          </h2>
          <p>{t("privacy.section3.content")}</p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mb-2">
            {t("privacy.section4.title")}
          </h2>
          <p>{t("privacy.section4.content")}</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
