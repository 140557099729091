import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./ui/AppLayout";
import NotFoundPage from "./pages/NotFoundPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/privacy-policy";
import CancellationPolicy from "./pages/cancellation-policy";
import FAQ from "./pages/faq";
import Popup from "./ui/Popup";
const Home = lazy(() => import("./pages/Home"));
const Offers = lazy(() => import("./pages/Offers"));
const OfferDetails = lazy(() => import("./pages/OfferDetails"));
const Destination = lazy(() => import("./pages/Destination"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
  return (
    <>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />

          <Route path="/offers" element={<Offers />} />
          <Route path="/offer/:offerId" element={<OfferDetails />} />
          <Route path="/destination/:destinationId" element={<Destination />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
