import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || "en"; // Detect the current language
  const isRTL = lang === "ar";

  return (
    <div className="container mx-auto px-4 py-8" dir={isRTL ? "rtl" : "ltr"}>
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        {t("terms.title")}
      </h1>
      <div className="space-y-4 text-gray-700">
        <p>{t("terms.intro")}</p>
        <h2 className="text-lg font-semibold">{t("terms.bookingsTitle")}</h2>
        <p>{t("terms.bookingsContent")}</p>
        <h2 className="text-lg font-semibold">{t("terms.paymentsTitle")}</h2>
        <p>{t("terms.paymentsContent")}</p>
        <h2 className="text-lg font-semibold">{t("terms.cancelationTitle")}</h2>
        <p>{t("terms.cancelationContent")}</p>
        <h2 className="text-lg font-semibold">{t("terms.liabilityTitle")}</h2>
        <p>{t("terms.liabilityContent")}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
