import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-5">
        <div className="bg-gradient-to-r from-purple-600 to-indigo-700 rounded-lg p-6 w-96 relative text-center shadow-2xl">
          <button
            onClick={closePopup}
            className="flex items-center pb-3  text-gray-200 hover:text-gray-400 text-2xl"
          >
            <IoClose />
          </button>
          <h2 className="text-3xl font-extrabold text-white">
            {t("popup.title")}
          </h2>
          <p className="mt-4 text-lg text-gray-100">
            {t("popup.description", { discount: "90%" })}
          </p>
          <p className="mt-2 text-gray-100">{t("popup.cta")}</p>
          <a
            href="https://wa.me/966565044018" // Replace with your WhatsApp number
            target="_blank"
            rel="noopener noreferrer"
            className="mt-5 inline-block bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-full shadow-lg transition-all duration-300"
          >
            {t("popup.button")}
          </a>
        </div>
      </div>
    )
  );
};

export default Popup;
