import React, { useEffect } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Popup from "./Popup";
import ChatIcons from "./ChatIcons";

const AppLayout = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    document.body.dir = i18n.language === "ar" ? "rtl" : "ltr";

    window.scrollTo(0, 0);
  }, [i18n.language, location]);
  return (
    <div
      className="h-screen min-h-screen"
      style={{
        fontFamily:
          i18next.language === "en"
            ? "Poppins, sans-serif"
            : "Almarai, sans-serif",
      }}
    >
      <Header />
      <Popup />
      <ChatIcons />
      <div className="pt-[50px] min-h-full  lg:px-0">
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default AppLayout;
