import React from "react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || "en";
  const isRTL = lang === "ar";

  const faqItems = t("faq.questions", { returnObjects: true });

  return (
    <div className="container mx-auto px-4 py-8" dir={isRTL ? "rtl" : "ltr"}>
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        {t("faq.title")}
      </h1>
      <div className="space-y-6">
        {faqItems.map((item, index) => (
          <div key={index} className="border-b pb-4">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              {item.question}
            </h2>
            <p className="text-gray-700">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
