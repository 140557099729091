import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Detect language and set text direction
  const lang = i18n.language || "en";
  const isRTL = lang === "ar";

  return (
    <div
      className="flex items-center justify-center h-screen bg-gray-100 text-center"
      dir={isRTL ? "rtl" : "ltr"}
    >
      <div className="max-w-lg p-8 bg-white shadow rounded-lg">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          {t("notFound.title")}
        </h1>
        <p className="text-gray-600 mb-6">{t("notFound.message")}</p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          {t("notFound.homeButton")}
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
