import React, { useState, useEffect, useRef } from "react";
import { FaCommentDots, FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ChatIcon = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed bottom-6 right-6 z-50" ref={menuRef}>
      {/* Main Chat Icon */}
      <button
        onClick={toggleMenu}
        className="bg-gradient-to-r from-blue-500 to-blue-600 text-white p-4 rounded-full shadow-xl hover:scale-110 transition-all duration-300"
      >
        <FaCommentDots className="text-3xl" />
      </button>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <div className="absolute bottom-20 right-0 bg-white shadow-xl rounded-lg p-4 w-64 border border-gray-200 animate-slide-up">
          <h3 className="text-lg font-semibold text-gray-700 mb-3 text-center">
            {t("chat.menuHeader")}
          </h3>
          <ul className="flex flex-col gap-4">
            {/* WhatsApp Option */}
            <li>
              <a
                href="https://wa.me/966565044018" // Replace with your WhatsApp number
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-green-600 hover:bg-green-100 p-3 rounded-lg transition-all duration-300"
              >
                <FaWhatsapp className="text-2xl" />
                <span className="text-gray-800 font-medium">
                  {t("chat.whatsapp")}
                </span>
              </a>
            </li>

            {/* Phone Option */}
            <li>
              <a
                href="tel:00966125348834" // Replace with your phone number
                className="flex items-center gap-4 text-yellow-600 hover:bg-yellow-100 p-3 rounded-lg transition-all duration-300"
              >
                <FaPhoneAlt className="text-2xl" />
                <span className="text-gray-800 font-medium">
                  {t("chat.phone")}
                </span>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChatIcon;
