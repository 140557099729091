import React, { useEffect, useState } from "react";
import logo1 from "../assets/logo.png";
import Mada from "../assets/NicePng_rupay-card-png_3654057.png";
import visa from "../assets/visa-logo-png-2028.png";
import logo from "../assets/download.jpg";
import { getPlacesData, getScoial } from "../services/apiOffers";
import { urlFor } from "../client";
import { MdLocationOn } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const [scoial, setScoial] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { scoial } = await getScoial();
        setScoial(scoial);
      } catch (err) {
        console.error("Failed to fetch social media links:", err);
      }
    };
    getData();
  }, []);

  return (
    <footer className="bg-gray-50 border-t border-gray-200 pb-8 pt-10 lg:px-0 px-2">
      <div className="max-w-screen-xl container mx-auto px-4 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-8">
          {/* Logo and About Section */}
          <div className="col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2">
            <img
              src={logo1}
              alt={t("footer.logoAlt")}
              className="lg:w-20 lg:h-20 w-24 h-24 mb-6 mx-auto sm:mx-0"
            />
            <p className="text-gray-600 text-base sm:text-base mb-4 leading-relaxed text-center sm:text-start">
              {t("footer.about")}
            </p>

            {/* Social Media Icons */}
            {scoial?.length > 0 && (
              <div className="flex justify-center sm:justify-start gap-4 mb-4">
                {scoial.map((data) => (
                  <a
                    key={data._id}
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-900"
                    aria-label={data.imgUrl?.alt || "Social Media Icon"}
                  >
                    <img
                      className="h-6 w-6 object-contain"
                      src={urlFor(data.imgUrl)}
                      alt={data?.imgUrl?.alt || "Social Media"}
                      loading="lazy"
                    />
                  </a>
                ))}
              </div>
            )}

            {/* Payment Methods */}
            <Payment />
          </div>

          {/* Services Section */}
          <FooterColumn
            title={t("footer.servicesTitle")}
            items={t("footer.services", { returnObjects: true })}
          />

          <FooterColumnDes title={t("footer.destinationsTitle")} />

          <FooterColumn
            title={t("footer.supportTitle")}
            items={t("footer.support", { returnObjects: true })}
          />

          {/* Contact Information Section */}
          <ContactInfo />
        </div>

        {/* Footer Bottom */}
        <div className="mt-10 border-t pt-6 text-gray-600 text-sm text-center ">
          <p>
            {t("footer.copyright")} © 2024 |{" "}
            <Link
              to="/terms-and-conditions"
              className="text-blue-500 hover:underline"
            >
              {t("footer.terms")}
            </Link>{" "}
            |{" "}
            <Link
              to="/privacy-policy"
              className="text-blue-500 hover:underline"
            >
              {t("footer.privacy")}
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

const FooterColumn = ({ title, items }) => (
  <div className="lg:mt-5">
    <h3 className="font-semibold text-gray-800 text-lg lg:text-xl mb-4">
      {title}
    </h3>
    <ul className="space-y-2 text-gray-600 text-base sm:text-base">
      {items?.map((item, index) => (
        <li key={index}>
          <a href={item.href} className="hover:underline">
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
const FooterColumnDes = ({ title }) => {
  const { i18n } = useTranslation();
  const [places, setPlaces] = useState([]); // State for storing places

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPlacesData(i18n.language); // Fetch places with the desired language
        setPlaces(data.slice(0, 5)); // Set only the first 5 places
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    };
    fetchData();
  }, [i18n.language]);

  return (
    <div className="lg:mt-5">
      <h3 className="font-semibold text-gray-800 text-lg lg:text-xl mb-4">
        {title}
      </h3>
      <ul className="space-y-2 text-gray-600 text-base sm:text-base">
        {places.map((place) => (
          <li key={place.id}>
            <a
              href={`/${place.slug}`} // Use slug for dynamic navigation
              className="hover:underline"
            >
              {place.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
const Payment = () => (
  <div className="mt-3 flex justify-center sm:justify-start items-center gap-4">
    <img src={Mada} alt="Mada" className="w-14 h-5 object-contain" />
    <img src={visa} alt="visa" className="w-14 h-5 object-contain" />
    <img src={logo} alt="Payment Logo" className="w-10 h-10 object-contain" />
  </div>
);

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="lg:mt-5">
      <h3 className="font-semibold text-gray-800 text-base sm:text-lg mb-4">
        {t("footer.contactTitle")}
      </h3>
      <ul className="space-y-2 text-gray-600 text-sm sm:text-base">
        <li>
          <a
            href="mailto:contact@newsky.online"
            className="hover:underline flex items-center gap-2"
          >
            <i className="fas fa-envelope"></i>
            contact@newsky.online
          </a>
        </li>
        <li>
          <a
            href="tel:00966125348834"
            className="hover:underline flex items-center gap-2"
          >
            <i className="fas fa-phone"></i>
            00966125348834
          </a>
        </li>
        <li>
          <a
            href="https://maps.app.goo.gl/RX49n98KXeoMocHi6"
            target="_blank"
            rel="noreferrer"
            className="flex items-start gap-2"
          >
            <MdLocationOn className="text-lg" />
            {t("footer.address")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
